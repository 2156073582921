<nav
  class="menu-container flex text-white bg-primary notHome"
  [ngClass]="{
    'small-menu': isSmall(),
    'fixed elevation-1': isFixed(),
    transition: isAni()
  }"
>
  <div class="flex flex-col container">
    <div class="absolute container text-right text-xs z-[2]">
      <div class="user-login inline-block relative">
        <app-i18n-menu></app-i18n-menu>
      </div>
    </div>

    <!-- absolute left-0 -->
    <div class="flex items-center w-full h-full">
      <a [routerLink]="['/'] | i18nLink" class="flex items-center flex-none">
        @if (rootData.info.bannerLogo; as bannerLogo) {
          @if (rootData.info.title | translate; as title) {
            @if ('小草國際開發有限公司' | translate; as subtitle) {
              <img
                alt="{{ title }}({{ subtitle }})"
                title="{{ title }}({{ subtitle }})"
                [src]="rootData.info.bannerLogo | img | safe: 'url'"
                class="rounded mr-2 transition"
              />
            }
          }
        }
      </a>
      <!-- </div> -->
      <i class="flex-auto"></i>
      <!-- *ngIf="showMenu()" -->

      <div class="flex items-center">
        <div class="navbar flex" [class.active]="_base.menuOpen$ | async">
          <div class="navbar-box transition-all">
            <!-- overflow-auto transition -->
            <app-menu-drop-down [menus]="menus()"></app-menu-drop-down>

            <!--
          <ng-container *ngIf="addMenu$ | async as addMenu">
              <ng-container *ngTemplateOutlet="addMenu, context: {
                $implicit: {
                  title : true,
                  sidenav: sidenav
                }
              }"></ng-container>
        </ng-container> -->
          </div>
          <div
            class="flex-auto"
            (touchstart)="_base.toggleMenu($event)"
            (mousedown)="_base.toggleMenu($event)"
          ></div>
        </div>
      </div>
      <div class="flex items-center">
        <!-- theme selector -->
        <!-- <button mat-icon-button #trigger="cdkOverlayOrigin" cdkOverlayOrigin (click)="selector.open()">
    <mat-icon>format_color_fill</mat-icon>
    <theme-color-selector #selector [selector]="trigger"></theme-color-selector>
  </button> -->
        <div (click)="_base.toggleMenu()" class="cursor-pointer lg:hidden">
          <app-menu-icon></app-menu-icon>
        </div>
      </div>
    </div>
  </div>
</nav>
